import React, { useState, useEffect } from 'react';
import { Box, Typography, TextField, Paper, Grid } from '@mui/material';
import { format, isValid } from 'date-fns';
import { FormSubmission } from '../../../services/firebase/submissionReader';

interface TotalDaysVisualizationProps {
  submissions: FormSubmission[];
  questionId?: string; // Make questionId optional with default to special ID
}

// Helper to safely convert Firebase timestamp to Date
const convertToDate = (timestamp: any): Date | null => {
  if (!timestamp) return null;
  
  try {
    // Handle Firestore timestamp with toDate method
    if (typeof timestamp.toDate === 'function') {
      return timestamp.toDate();
    }
    
    // Handle serialized Firestore timestamp with seconds
    if (timestamp.seconds !== undefined) {
      return new Date(timestamp.seconds * 1000);
    }
    
    // Handle regular Date object
    if (timestamp instanceof Date) {
      return timestamp;
    }
    
    // Try to parse as date string
    const date = new Date(timestamp);
    return isValid(date) ? date : null;
  } catch (e) {
    console.error("Error converting timestamp to date:", e, timestamp);
    return null;
  }
};

const calculateTotalDaysAndHours = (
  submissions: FormSubmission[], 
  startDateFilter: Date, 
  endDateFilter: Date, 
  questionId: string
): Record<string, string> => {
  const userDays: Record<string, { days: number, hours: number }> = {};
  let processedCount = 0;

  // Log filter dates for debugging
  console.log(`Filtering by date range: ${startDateFilter.toISOString()} to ${endDateFilter.toISOString()}`);
  console.log(`Processing ${submissions.length} submissions for total days calculation with question ID: ${questionId}`);
  
  submissions.forEach(submission => {
    const { email, responses } = submission;
    
    // Check if the response exists for the specified date range question
    if (responses && responses[questionId]) {
      const dateRange = responses[questionId];
      
      // Convert to proper dates, handling Firebase timestamps
      const start = convertToDate(dateRange.startDate);
      const end = convertToDate(dateRange.endDate);
      
      // Skip if we couldn't properly convert the dates
      if (!start || !end) {
        console.warn('Invalid date range found:', dateRange);
        return;
      }
      
      // Log for debugging
      console.log(`Processing date range for ${email}: ${start.toISOString()} to ${end.toISOString()}`, 
        { useHours: dateRange.useHours, hours: dateRange.hours });
      
      // Filter by date range - only include ranges that overlap with the filter period
      if (end < startDateFilter || start > endDateFilter) {
        console.log(`Skipping date range for ${email} - outside filter period`);
        return;
      }
      
      const diffTime = end.getTime() - start.getTime();
      const days = Math.floor(diffTime / (1000 * 60 * 60 * 24));
      const totalHours = dateRange.useHours ? (dateRange.hours || 0) : Math.floor((diffTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      
      if (!userDays[email]) {
        userDays[email] = { days: 0, hours: 0 };
      }
      userDays[email].days += days;
      userDays[email].hours += totalHours;
      
      processedCount++;
    }
  });
  
  console.log(`Successfully processed ${processedCount} submissions with date ranges`);

  // Convert to display format
  const result: Record<string, string> = {};
  for (const [email, { days, hours }] of Object.entries(userDays)) {
    // Normalize hours - convert excess hours to days
    const normalizedDays = days + Math.floor(hours / 24);
    const normalizedHours = hours % 24;
    
    result[email] = `${normalizedDays}d ${normalizedHours}h`;
  }
  return result;
};

const TotalDaysVisualization: React.FC<TotalDaysVisualizationProps> = ({ 
  submissions,
  questionId = '28048bd2-847b-4e11-b949-31e12c164ee7' // Default to the special question ID
}) => {
  // Use a useEffect to log when the component renders and with how many submissions
  useEffect(() => {
    console.log(`TotalDaysVisualization rendering with ${submissions.length} submissions for question ${questionId}`);
    if (submissions.length > 0) {
      console.log('First submission sample:', {
        id: submissions[0].id,
        hasDateResponse: submissions[0].responses && 
          !!submissions[0].responses[questionId],
        responseExample: submissions[0].responses && 
          submissions[0].responses[questionId]
      });
    }
  }, [submissions, questionId]);

  // Set default filter dates - 1 year ago to current date
  const defaultStartDate = new Date();
  defaultStartDate.setFullYear(defaultStartDate.getFullYear() - 1);
  
  const [startDateFilter, setStartDateFilter] = useState(defaultStartDate);
  const [endDateFilter, setEndDateFilter] = useState(new Date());

  // Calculate user days with the date filter
  const userDays = calculateTotalDaysAndHours(submissions, startDateFilter, endDateFilter, questionId);
  const userCount = Object.keys(userDays).length;

  // Format date for the input field
  const formatDateForInput = (date: Date): string => {
    return format(date, 'yyyy-MM-dd');
  };

  // Add effect to recalculate when filter dates change
  useEffect(() => {
    console.log(`Date filter changed: ${startDateFilter.toISOString()} to ${endDateFilter.toISOString()}`);
  }, [startDateFilter, endDateFilter]);

  return (
    <Box>
      <Grid container spacing={2} sx={{ mb: 2 }}>
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            label="Start Date"
            type="date"
            fullWidth
            size="small"
            value={formatDateForInput(startDateFilter)}
            onChange={(e) => {
              const newDate = new Date(e.target.value);
              if (isValid(newDate)) {
                setStartDateFilter(newDate);
              }
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            label="End Date"
            type="date"
            fullWidth
            size="small"
            value={formatDateForInput(endDateFilter)}
            onChange={(e) => {
              const newDate = new Date(e.target.value);
              if (isValid(newDate)) {
                setEndDateFilter(newDate);
              }
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
      </Grid>
      
      {userCount > 0 ? (
        <Grid container spacing={1.5}>
          {Object.entries(userDays).map(([email, time]) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={email}>
              <Paper 
                elevation={0} 
                sx={{ 
                  p: 1.5, 
                  bgcolor: 'background.default',
                  border: '1px solid',
                  borderColor: 'divider',
                  borderRadius: 1,
                  display: 'flex',
                  flexDirection: 'column'
                }}
              >
                <Typography variant="subtitle2" sx={{ fontWeight: 'medium', fontSize: '0.9rem', mb: 0.5 }}>
                  {email}
                </Typography>
                <Typography variant="h6" color="primary" sx={{ fontSize: '1.1rem' }}>
                  {time}
                </Typography>
              </Paper>
            </Grid>
          ))}
        </Grid>
      ) : (
        <Box sx={{ textAlign: 'center', py: 2 }}>
          <Typography variant="body1" color="text.secondary">
            No date range data found in the current submissions for this time period
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default TotalDaysVisualization;
