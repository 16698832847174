import { format, differenceInMinutes, parseISO } from 'date-fns';

// Generate theme-based colors for charts
export const generateChartColors = (theme: any, count: number) => {
  const baseColors = [
    theme.palette.primary.main,
    theme.palette.secondary.main,
    theme.palette.success.main,
    theme.palette.info.main,
    theme.palette.warning.main,
    theme.palette.error.main,
  ];
  
  // For additional colors, create variations of the base colors
  const colors = [];
  for (let i = 0; i < count; i++) {
    if (i < baseColors.length) {
      colors.push(baseColors[i]);
    } else {
      // Create variations by adjusting opacity
      const baseIndex = i % baseColors.length;
      const opacity = 0.7 - (Math.floor(i / baseColors.length) * 0.15);
      colors.push(baseColors[baseIndex] + Math.floor(opacity * 255).toString(16).padStart(2, '0'));
    }
  }
  
  return colors;
};

// Safely handle date parsing for chart labels
export const formatSubmissionLabel = (submission: any, index: number) => {
  // Use email prefix if available
  if (submission.email) {
    const emailParts = submission.email.split('@');
    return emailParts[0];
  }
  
  // Use form ID or index as fallback
  return submission.formId || submission.id || `Submission ${index + 1}`;
};

// Sort submissions by date (newest first) with safe date handling
export const sortSubmissionsByDate = (submissions: any[] = []) => {
  return [...submissions].sort((a, b) => {
    // Handle Firestore timestamps
    const getTimestamp = (sub: any) => {
      if (!sub.submittedAt) return 0;
      
      // Handle Firestore timestamp
      if (typeof sub.submittedAt.toDate === 'function') {
        return sub.submittedAt.toDate().getTime();
      }
      
      // Handle timestamp with seconds
      if (sub.submittedAt.seconds) {
        return sub.submittedAt.seconds * 1000;
      }
      
      // Handle Date objects
      if (sub.submittedAt instanceof Date) {
        return sub.submittedAt.getTime();
      }
      
      // Try to parse as date string
      try {
        return new Date(sub.submittedAt).getTime();
      } catch (e) {
        return 0;
      }
    };
    
    return getTimestamp(b) - getTimestamp(a); // Most recent first
  });
};

// Format numeric values for display
export const formatNumber = (value: number, precision: number = 1): string => {
  if (Number.isInteger(value)) return value.toString();
  return value.toFixed(precision);
};

// Calculate statistics from an array of numbers
export const calculateStats = (values: number[]) => {
  if (!values || values.length === 0) {
    return { min: 0, max: 0, avg: 0, median: 0 };
  }
  
  const sortedValues = [...values].sort((a, b) => a - b);
  const min = sortedValues[0];
  const max = sortedValues[sortedValues.length - 1];
  const sum = sortedValues.reduce((a, b) => a + b, 0);
  const avg = sum / sortedValues.length;
  
  // Calculate median
  const mid = Math.floor(sortedValues.length / 2);
  const median = sortedValues.length % 2 === 0
    ? (sortedValues[mid - 1] + sortedValues[mid]) / 2
    : sortedValues[mid];
    
  return { min, max, avg, median };
};

// Group dates by period (day, week, month)
export const groupDatesByPeriod = (dates: Date[], period: 'day' | 'week' | 'month') => {
  const groups: Record<string, number> = {};
  
  dates.forEach(date => {
    let key: string;
    
    if (period === 'day') {
      key = format(date, 'yyyy-MM-dd');
    } else if (period === 'week') {
      // Use ISO week format (year-week)
      key = format(date, 'yyyy-ww');
    } else {
      // Month
      key = format(date, 'yyyy-MM');
    }
    
    groups[key] = (groups[key] || 0) + 1;
  });
  
  return groups;
};

// Convert groupedData to chart-friendly format
export const formatGroupedDataForChart = (
  groupedData: Record<string, number>, 
  period: 'day' | 'week' | 'month'
) => {
  const sortedKeys = Object.keys(groupedData).sort();
  
  // Format labels based on period
  const labels = sortedKeys.map(key => {
    const [year, periodValue] = key.split('-');
    
    if (period === 'day') {
      // For days, show the date in a more readable format
      const [_, month, day] = key.split('-');
      return `${month}/${day}`;
    } else if (period === 'week') {
      // For weeks, show "Week X"
      return `Week ${periodValue}`;
    } else {
      // For months, show the month name
      const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
      const monthIndex = parseInt(periodValue) - 1;
      return monthNames[monthIndex];
    }
  });
  
  // Get the corresponding values
  const data = sortedKeys.map(key => groupedData[key]);
  
  return { labels, data };
};

// Helper to create a Filter Chip component 
export interface FilterIndicatorProps {
  activeFilter: {value: string, label: string} | null;
  clearFilter: () => void;
}