import React, { useState, useEffect } from 'react';
import { Box, Typography, Grid, Paper, ButtonGroup, Button } from '@mui/material';
import { BarChart, PieChart, CalendarMonth, ViewDay } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import { format, differenceInDays, isValid } from 'date-fns';
import { useNormalizedData } from '../VisualizationHelper';
import { TotalDaysVisualization } from '../';

// Define interfaces for our data types
interface DateRange {
  start: Date;
  end: Date;
  email?: string;
  label?: string;
  useHours?: boolean;
  hours?: number | null;
  includeWeekends?: boolean;
}

// Date formatting helper
const formatDate = (date: Date): string => {
  try {
    if (!date || !isValid(date)) return 'Invalid date';
    return format(date, 'MMM d, yyyy');
  } catch (e) {
    console.error('Error formatting date:', e);
    return 'Invalid date';
  }
};

interface DateVisualizationProps {
  question: any;
  summaryData: any;
  filteredSubmissions?: any[];
}

const DateVisualization: React.FC<DateVisualizationProps> = ({ 
  question, 
  summaryData,
  filteredSubmissions = []
}) => {
  const theme = useTheme();
  const normalizedData = useNormalizedData(question, summaryData, filteredSubmissions);
  
  // Check if this is a date range visualization
  const isDateRange = question.type === 'date-range';
  const isSpecialDateRange = question.id === '28048bd2-847b-4e11-b949-31e12c164ee7';
  
  // Set default view mode to 'totaldays' for the special date range question
  const [viewMode, setViewMode] = useState<'calendar'|'totaldays'>(
    isSpecialDateRange ? 'totaldays' : 'calendar'
  );
  
  if (!normalizedData) {
    return (
      <Box textAlign="center" py={2}>
        <Typography variant="body2" color="text.secondary">Loading date data...</Typography>
      </Box>
    );
  }
  
  // For date-range questions, show date ranges with labels
  if (isDateRange) {
    const dateRanges = normalizedData.dateRanges || [];
    
    // Special case for the totalDays visualization
    if (isSpecialDateRange) {
      return (
        <Box>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
            <ButtonGroup size="small" variant="outlined">
              <Button 
                onClick={() => setViewMode('calendar')}
                variant={viewMode === 'calendar' ? 'contained' : 'outlined'}
                startIcon={<CalendarMonth />}
              >
                Calendar View
              </Button>
              <Button 
                onClick={() => setViewMode('totaldays')}
                variant={viewMode === 'totaldays' ? 'contained' : 'outlined'}
                startIcon={<ViewDay />}
              >
                Total Days
              </Button>
            </ButtonGroup>
          </Box>
          
          {viewMode === 'totaldays' ? (
            <TotalDaysVisualization 
              submissions={filteredSubmissions}
              questionId={question.id}
            />
          ) : (
            <Box>
              {dateRanges.length === 0 ? (
                <Typography variant="body2" color="text.secondary" textAlign="center">
                  No date range data available
                </Typography>
              ) : (
                <Grid container spacing={2}>
                  {dateRanges.map((range: DateRange, index: number) => (
                    <Grid item xs={12} key={index}>
                      <Paper 
                        elevation={0} 
                        sx={{ 
                          p: 2, 
                          bgcolor: 'background.default',
                          border: '1px solid',
                          borderColor: 'divider'
                        }}
                      >
                        <Grid container alignItems="center">
                          <Grid item xs={12} sm={4}>
                            <Typography variant="subtitle2" sx={{ fontWeight: 'medium' }}>
                              {range.email || range.label || `Response ${index + 1}`}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={8}>
                            <Box>
                              <Typography variant="body1">
                                <strong>From:</strong> {formatDate(range.start)}
                              </Typography>
                              <Typography variant="body1">
                                <strong>To:</strong> {formatDate(range.end)}
                              </Typography>
                              <Typography variant="body2" color="primary.main">
                                {differenceInDays(range.end, range.start)} days
                                {range.useHours && range.hours !== null && 
                                  ` ${range.hours} hours`}
                              </Typography>
                            </Box>
                          </Grid>
                        </Grid>
                      </Paper>
                    </Grid>
                  ))}
                </Grid>
              )}
            </Box>
          )}
        </Box>
      );
    }
    
    // Regular date range visualization
    return (
      <Box>
        {dateRanges.length === 0 ? (
          <Typography variant="body2" color="text.secondary" textAlign="center">
            No date range data available
          </Typography>
        ) : (
          <Grid container spacing={2}>
            {dateRanges.map((range: DateRange, index: number) => (
              <Grid item xs={12} key={index}>
                <Paper 
                  elevation={0} 
                  sx={{ 
                    p: 2, 
                    bgcolor: 'background.default',
                    border: '1px solid',
                    borderColor: 'divider'
                  }}
                >
                  <Grid container alignItems="center">
                    <Grid item xs={12} sm={4}>
                      <Typography variant="subtitle2" sx={{ fontWeight: 'medium' }}>
                        {range.email || range.label || `Response ${index + 1}`}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <Box>
                        <Typography variant="body1">
                          <strong>From:</strong> {formatDate(range.start)}
                        </Typography>
                        <Typography variant="body1">
                          <strong>To:</strong> {formatDate(range.end)}
                        </Typography>
                        <Typography variant="body2" color="primary.main">
                          {differenceInDays(range.end, range.start)} days
                          {range.useHours && range.hours !== null && 
                            ` ${range.hours} hours`}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            ))}
          </Grid>
        )}
      </Box>
    );
  }
  
  // For single date questions
  const dateResponses = normalizedData.dateResponses || [];
  
  return (
    <Box>
      {dateResponses.length === 0 ? (
        <Typography variant="body2" color="text.secondary" textAlign="center">
          No date data available
        </Typography>
      ) : (
        <Grid container spacing={2}>
          {dateResponses.map((date: Date, index: number) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Paper 
                elevation={0} 
                sx={{ 
                  p: 2, 
                  bgcolor: 'background.default',
                  border: '1px solid',
                  borderColor: 'divider'
                }}
              >
                <Typography variant="body1">
                  {formatDate(date)}
                </Typography>
              </Paper>
            </Grid>
          ))}
        </Grid>
      )}
    </Box>
  );
};

export default DateVisualization;