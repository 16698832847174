import React, { useState, useEffect } from 'react';
import { 
  Box, Typography, Container, TextField, Button, 
  Paper, Divider, CircularProgress, Switch, FormControlLabel,
  Card, IconButton, List, ListItem, ListItemText,
  Accordion, AccordionSummary, AccordionDetails, Dialog, DialogTitle,
  DialogContent, DialogActions, Chip, FormControl, InputLabel, Select, MenuItem, Alert,
  useMediaQuery
} from '@mui/material';
import { useUser } from '@clerk/clerk-react';
import { doc, getDoc, setDoc, collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../firebase';
import SharedNavBar from '../components/SharedNavBar';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { themeOptions, createAppTheme } from "../theme/themeOptions";
import { ExpandMore, Add, Delete, Edit, Visibility, VisibilityOff, Lock, LockOpen } from '@mui/icons-material';
import { syncProfileToLandingPage } from '../utils/profileSync';

interface FAQ {
  id: string;
  question: string;
  answer: string;
}

interface FormWithFAQ {
  id: string;
  title: string;
  description?: string;
  faqs: FAQ[];
  selected: boolean;
}

interface CompanyInfo {
  companyName: string;
  companyLogo: string | null;
  shortCompanyName: string;
  theme?: string;  // Add theme as an optional property
}

const resolveThemeByName = (name: string) => {
  return themeOptions.find(theme => theme.name === name) || themeOptions[0];
};

const LandingPageBuilder: React.FC = () => {
  const { user } = useUser();
  
  // Add state for the actual theme to use
  const [userTheme, setUserTheme] = useState<string>("Blue");
  const theme = createAppTheme(userTheme);

  // Landing page state
  const [landingPage, setLandingPage] = useState({
    title: '',
    description: '',
    companySlug: '',
    isActive: true,
    theme: 'Blue', // Default theme
    isPasswordProtected: false, // Add password protection state
    password: '' // Add password state
  });
  
  // Forms state
  const [forms, setForms] = useState<FormWithFAQ[]>([]);
  
  // UI state
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [slugAvailable, setSlugAvailable] = useState(true);
  const [slugChecking, setSlugChecking] = useState(false);
  
  // User profile data
  const [companyName, setCompanyName] = useState('');
  const [shortCompanyName, setShortCompanyName] = useState('');
  const [companyLogo, setCompanyLogo] = useState<string | null>(null);
  
  // FAQ Editor state
  const [editingFormIndex, setEditingFormIndex] = useState<number | null>(null);
  const [editingFAQ, setEditingFAQ] = useState<FAQ | null>(null);
  const [faqDialogOpen, setFaqDialogOpen] = useState(false);
  const [faqQuestion, setFaqQuestion] = useState('');
  const [faqAnswer, setFaqAnswer] = useState('');
  
  // Add a new state to track the selected form in the preview
  const [previewSelectedFormId, setPreviewSelectedFormId] = useState<string>('');

  // Simple approach for admin selection
  const [targetUserId, setTargetUserId] = useState<string | null>(null);
  const [users, setUsers] = useState<Array<{id: string, name: string}>>([]);
  const [userRole, setUserRole] = useState("User");

  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  // Add state for password visibility toggle
  const [showPassword, setShowPassword] = useState(false);

  // Load user role
  useEffect(() => {
    const loadUserRole = async () => {
      if (!user) return;
      
      try {
        const userDoc = await getDoc(doc(db, "users", user.id));
        if (userDoc.exists()) {
          setUserRole(userDoc.data().role || "User");
        }
      } catch (error) {
        console.error("Error loading user role:", error);
      }
    };
    
    loadUserRole();
  }, [user]);

  // Load users list if admin
  useEffect(() => {
    if (userRole !== "Admin" || !user) return;
    
    const loadUsers = async () => {
      try {
        const usersSnapshot = await getDocs(collection(db, "users"));
        const usersData = usersSnapshot.docs.map(doc => ({
          id: doc.id,
          name: doc.data().companyName || `User ${doc.id.substring(0, 6)}`
        }));
        setUsers(usersData);
      } catch (error) {
        console.error("Error loading users:", error);
      }
    };
    
    loadUsers();
  }, [userRole, user]);

  // Initialize the preview with the first selected form when forms change
  useEffect(() => {
    const selectedForms = forms.filter(f => f.selected);
    if (selectedForms.length > 0 && !selectedForms.some(f => f.id === previewSelectedFormId)) {
      setPreviewSelectedFormId(selectedForms[0].id);
    }
  }, [forms]);

  // Add a useEffect to sync on component load
  useEffect(() => {
    const syncProfileData = async () => {
      if (user) {
        await syncProfileToLandingPage(user.id);
      }
    };
    
    syncProfileData();
  }, [user]);

  // Add an effect to load the theme from the appropriate user
  useEffect(() => {
    const loadUserTheme = async () => {
      if (!user) return;
      
      const effectiveUserId = targetUserId || user.id;
      
      try {
        const userDoc = await getDoc(doc(db, "users", effectiveUserId));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          // Set the theme from the appropriate user
          const userThemeValue = userData.theme || "Blue";
          setUserTheme(userThemeValue);
          
          // Also update the landing page theme if we're initializing
          setLandingPage(prev => ({
            ...prev,
            theme: userThemeValue
          }));
        }
      } catch (error) {
        console.error("Error loading user theme:", error);
      }
    };
    
    loadUserTheme();
  }, [user, targetUserId]);

  // Load user profile, forms, and landing page data
  useEffect(() => {
    const loadData = async () => {
      if (!user) return;
      
      const effectiveUserId = targetUserId || user.id;
      
      try {
        setLoading(true);
        
        // 1. Load appropriate user profile for company info
        const userDoc = await getDoc(doc(db, "users", effectiveUserId));
        const userData = userDoc.exists() 
          ? userDoc.data() as {
              companyName?: string;
              shortCompanyName?: string;
              companyLogo?: string | null;
              theme?: string;
            } 
          : {};
          
        setCompanyName(userData.companyName || '');
        setShortCompanyName(userData.shortCompanyName || '');
        setCompanyLogo(userData.companyLogo || null);
        
        // Set the user's theme
        const userThemeValue = userData.theme || "Blue";
        setUserTheme(userThemeValue);
        
        // 2. Load target user's forms
        const formsQuery = query(collection(db, "forms"), where("userId", "==", effectiveUserId));
        const formsSnapshot = await getDocs(formsQuery);
        const userForms = formsSnapshot.docs.map(doc => {
          const formData = doc.data();
          // Check for both possible formats (formTitle and title)
          return {
            id: doc.id,
            title: formData.formTitle || formData.title || 'Untitled Form',
            description: formData.description || '',
            faqs: [],
            selected: false
          };
        });

        console.log("Loaded forms:", userForms); // Add logging to debug
        
        // 3. Check if landing page exists for target user
        const landingPageDoc = await getDoc(doc(db, "landing_pages", effectiveUserId));
        if (landingPageDoc.exists()) {
          const data = landingPageDoc.data();
          setLandingPage({
            title: data.title || '',
            description: data.description || '',
            companySlug: data.companySlug || '',
            isActive: data.isActive !== false,
            theme: data.theme || userThemeValue, // Use landing page theme or user theme
            isPasswordProtected: data.isPasswordProtected || false, // Load password protection state
            password: data.password || '' // Load password
          });
          
          // Make sure we use the landing page theme in the app too if it exists
          if (data.theme) {
            setUserTheme(data.theme);
          }

          // Merge saved form data with current forms
          const updatedForms = userForms.map(form => {
            const savedForm = data.forms?.find((f: any) => f.id === form.id);
            return savedForm ? {
              ...form,
              faqs: savedForm.faqs || [],
              selected: true
            } : form;
          });
          
          setForms(updatedForms);
        } else {
          // Initialize with default values based on target user's data
          setLandingPage(prev => ({
            ...prev,
            title: companyName ? `${companyName} Forms` : 'Our Forms',
            description: 'Please select a form to complete',
            companySlug: generateSlug(shortCompanyName || companyName || user.username || user.firstName || ''),
            theme: userThemeValue // Use user's theme preference
          }));
          
          setForms(userForms);
          
          // Check slug availability
          checkSlugAvailability(generateSlug(shortCompanyName || companyName || user.username || user.firstName || ''));
        }
      } catch (error) {
        console.error("Error loading data:", error);
      } finally {
        setLoading(false);
      }
    };
    
    loadData();
  }, [user, companyName, shortCompanyName, targetUserId]);

  // When the landing page theme changes, update the active theme too
  useEffect(() => {
    setUserTheme(landingPage.theme);
  }, [landingPage.theme]);
  
  // Generate a URL-friendly slug from text
  const generateSlug = (text: string): string => {
    return text.toLowerCase()
      .replace(/[^a-z0-9]+/g, '-')
      .replace(/^-+|-+$/g, '');
  };
  
  // Check if slug is available
  const checkSlugAvailability = async (slug: string) => {
    if (!slug) return;
    
    try {
      setSlugChecking(true);
      
      // Check if slug is already taken
      const slugQuery = query(
        collection(db, "landing_pages"), 
        where("companySlug", "==", slug)
      );
      
      const slugSnapshot = await getDocs(slugQuery);
      
      // Slug is available if no documents found or the only document is the user's own
      setSlugAvailable(
        slugSnapshot.empty || 
        (slugSnapshot.size === 1 && slugSnapshot.docs[0].id === user?.id)
      );
    } catch (error) {
      console.error("Error checking slug availability:", error);
    } finally {
      setSlugChecking(false);
    }
  };
  
  // Handle slug change
  const handleSlugChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newSlug = generateSlug(e.target.value);
    setLandingPage(prev => ({ ...prev, companySlug: newSlug }));
    checkSlugAvailability(newSlug);
  };
  
  // Toggle form selection
  const toggleFormSelection = (index: number) => {
    setForms(prev => prev.map((form, i) => 
      i === index ? { ...form, selected: !form.selected } : form
    ));
  };
  
  // Open FAQ editor for a form
  const openFAQEditor = (formIndex: number) => {
    setEditingFormIndex(formIndex);
  };
  
  // Add a new FAQ
  const addNewFAQ = () => {
    setEditingFAQ(null);
    setFaqQuestion('');
    setFaqAnswer('');
    setFaqDialogOpen(true);
  };
  
  // Edit existing FAQ
  const editFAQ = (faq: FAQ) => {
    setEditingFAQ(faq);
    setFaqQuestion(faq.question);
    setFaqAnswer(faq.answer);
    setFaqDialogOpen(true);
  };
  
  // Delete FAQ
  const deleteFAQ = (faqId: string) => {
    if (editingFormIndex === null) return;
    
    setForms(prev => prev.map((form, index) => {
      if (index !== editingFormIndex) return form;
      
      return {
        ...form,
        faqs: form.faqs.filter(faq => faq.id !== faqId)
      };
    }));
  };
  
  // Save FAQ
  const saveFAQ = () => {
    if (editingFormIndex === null || !faqQuestion.trim() || !faqAnswer.trim()) {
      setFaqDialogOpen(false);
      return;
    }
    
    setForms(prev => prev.map((form, index) => {
      if (index !== editingFormIndex) return form;
      
      if (editingFAQ) {
        // Update existing FAQ
        return {
          ...form,
          faqs: form.faqs.map(faq => 
            faq.id === editingFAQ.id 
              ? { ...faq, question: faqQuestion, answer: faqAnswer }
              : faq
          )
        };
      } else {
        // Add new FAQ
        return {
          ...form,
          faqs: [...form.faqs, {
            id: `faq_${Date.now()}`,
            question: faqQuestion,
            answer: faqAnswer
          }]
        };
      }
    }));
    
    setFaqDialogOpen(false);
  };
  
  // Close FAQ editor
  const closeFAQEditor = () => {
    setEditingFormIndex(null);
  };
  
  const saveLandingPage = async () => {
    if (!user || !landingPage.companySlug || !slugAvailable) return;
    
    const effectiveUserId = targetUserId || user.id;
    
    try {
      setSaving(true);
      
      // Get latest company info from user's profile
      const userDoc = await getDoc(doc(db, "users", effectiveUserId));
      let companyInfo: CompanyInfo = {
        companyName,
        companyLogo,
        shortCompanyName
      };
      
      if (userDoc.exists()) {
        const userData = userDoc.data();
        companyInfo = {
          companyName: userData.companyName || companyName,
          companyLogo: userData.companyLogo || companyLogo,
          shortCompanyName: userData.shortCompanyName || shortCompanyName,
          theme: userData.theme || "Blue"  // Default to Blue if not set
        };
      }
      
      // Use short company name as slug if available
      const effectiveSlug = companyInfo.shortCompanyName || landingPage.companySlug;
      
      // Prepare forms data
      const selectedFormsList = forms.filter(form => form.selected);
      
      // Get the latest data for each selected form
      const selectedFormsPromises = selectedFormsList.map(async (form) => {
        try {
          // Fetch the latest form data
          const formDoc = await getDoc(doc(db, "forms", form.id));
          
          if (formDoc.exists()) {
            const formData = formDoc.data();
            return { 
              id: form.id, 
              title: formData.formTitle || formData.title || form.title || 'Untitled Form',
              description: formData.description || formData.formDescription || form.description || '',
              formSlug: formData.formSlug || '', // Add the latest formSlug
              faqs: form.faqs 
            };
          } else {
            return { 
              id: form.id, 
              title: form.title || 'Untitled Form',
              description: form.description || '',
              faqs: form.faqs 
            };
          }
        } catch (error) {
          console.error(`Error fetching latest data for form ${form.id}:`, error);
          return { 
            id: form.id, 
            title: form.title || 'Untitled Form',
            description: form.description || '',
            faqs: form.faqs 
          };
        }
      });
      
      const selectedForms = await Promise.all(selectedFormsPromises);
      
      await setDoc(doc(db, "landing_pages", effectiveUserId), {
        userId: effectiveUserId,
        ...landingPage,
        companySlug: effectiveSlug,
        forms: selectedForms,
        companyName: companyInfo.companyName,
        companyLogo: companyInfo.companyLogo,
        theme: companyInfo.theme || landingPage.theme || "Blue",  // Handle theme properly
        updatedAt: new Date()
      });
      
      // Update the local state
      setLandingPage(prev => ({
        ...prev,
        companySlug: effectiveSlug
      }));
      
      alert("Landing page saved successfully!");
    } catch (error) {
      console.error("Error saving landing page:", error);
      alert("Failed to save landing page. Please try again.");
    } finally {
      setSaving(false);
    }
  };
  
  if (loading) {
    return (
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center',
        height: '100vh' 
      }}>
        <CircularProgress />
      </Box>
    );
  }

  function handlePreviewFormChange(event: React.ChangeEvent<HTMLSelectElement>): void {
    const selectedFormId = event.target.value;
    setPreviewSelectedFormId(selectedFormId);
  }

  // Update the handleGoToForm function in the preview section
  const handlePreviewFormSelection = async (formId: string) => {
    if (!formId) return;
    
    try {
      // Fetch the current form data to get the latest slug
      const formDoc = await getDoc(doc(db, "forms", formId));
      if (!formDoc.exists()) {
        console.error("Form not found");
        return;
      }
      
      const formData = formDoc.data();
      const formTitle = formData.formTitle || formData.title || 'Untitled Form';
      const formSlug = formData.formSlug;
      
      // Determine the appropriate URL
      let formUrl;
      if (landingPage.companySlug && formSlug) {
        formUrl = `/${landingPage.companySlug}/${formSlug}`;
      } else {
        formUrl = `/fill/${formId}`;
      }
      
      // Open the form
      window.open(formUrl, '_blank');
    } catch (error) {
      console.error("Error opening form:", error);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ bgcolor: theme.palette.background.default, minHeight: '100vh' }}>
        <SharedNavBar title="Landing Page Builder" />
        
        <Container maxWidth="md" sx={{ 
          mt: { xs: 2, sm: 4 }, 
          mb: { xs: 4, sm: 8 },
          px: { xs: 1, sm: 3 }
        }}>
          <Paper sx={{ p: { xs: 2, sm: 4 }, borderRadius: 2, mb: 4 }}>
            <Typography variant="h4" gutterBottom>
              Landing Page Builder
            </Typography>
            <Typography variant="body1" color="text.secondary" paragraph>
              Create a landing page where users can select which form to fill out, with FAQs for each form.
            </Typography>
            
            <Divider sx={{ my: 3 }} />
            
            {/* Admin: Select User */}
            {userRole === "Admin" && (
              <Box mb={4}>
                <FormControl fullWidth>
                  <InputLabel>Select User</InputLabel>
                  <Select
                    value={targetUserId || (user ? user.id : "")}
                    onChange={(e) => setTargetUserId(e.target.value || null)}
                    label="Select User"
                  >
                    {user && <MenuItem value={user.id}>Your Landing Page</MenuItem>}
                    {users.map(u => (
                      <MenuItem key={u.id} value={u.id}>{u.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            )}
            
            {/* Basic Settings */}
            <Box sx={{ mb: 4 }}>
              <Typography variant="h6" gutterBottom>
                Basic Settings
              </Typography>
              
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
                <TextField
                  label="Page Title"
                  value={landingPage.title}
                  onChange={(e) => setLandingPage(prev => ({ ...prev, title: e.target.value }))}
                  fullWidth
                />
                
                <TextField
                  label="Page Description"
                  value={landingPage.description}
                  onChange={(e) => setLandingPage(prev => ({ ...prev, description: e.target.value }))}
                  fullWidth
                  multiline
                  rows={2}
                  helperText="This will appear above the form selection dropdown"
                />
                
                <Box>
                  <TextField
                    label="URL Slug"
                    value={landingPage.companySlug}
                    onChange={handleSlugChange}
                    fullWidth
                    helperText={
                      slugChecking 
                        ? "Checking availability..." 
                        : slugAvailable 
                          ? `Your page will be available at formcraft.co.uk/${landingPage.companySlug}` 
                          : "This URL is already taken. Please try another."
                    }
                    error={!slugAvailable && !slugChecking}
                    InputProps={{
                      startAdornment: <Box component="span" sx={{ color: 'text.secondary' }}>formcraft.co.uk/</Box>
                    }}
                  />
                </Box>

                <TextField
                  fullWidth
                  variant="outlined"
                  size="small"
                  value={`${window.location.origin}/${landingPage.companySlug}`}
                  InputProps={{ readOnly: true }}
                />
                
                <FormControlLabel
                  control={
                    <Switch 
                      checked={landingPage.isActive}
                      onChange={(e) => setLandingPage(prev => ({ ...prev, isActive: e.target.checked }))}
                    />
                  }
                  label="Landing Page Active"
                />

                <Box sx={{ mt: 3 }}>
                  <Typography variant="subtitle1" gutterBottom>
                    Theme
                  </Typography>
                  <FormControl fullWidth>
                    <InputLabel>Select Theme</InputLabel>
                    <Select
                      value={landingPage.theme || 'Blue'}
                      onChange={(e) => {
                        const newTheme = e.target.value;
                        setLandingPage(prev => ({ ...prev, theme: newTheme }));
                        setUserTheme(newTheme); // Update the active theme too
                      }}
                      label="Select Theme"
                      MenuProps={{
                        PaperProps: {
                          style: { maxHeight: 300 }
                        }
                      }}
                    >
                      {themeOptions.map((theme) => (
                        <MenuItem key={theme.name} value={theme.name}>
                          <Box display="flex" alignItems="center">
                            <Box
                              sx={{
                                width: 20,
                                height: 20,
                                borderRadius: '50%',
                                backgroundColor: theme.primary,
                                mr: 1
                              }}
                            />
                            {theme.name}
                          </Box>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>

                <Box sx={{ mt: 3 }}>
                  <Typography variant="subtitle1" gutterBottom>
                    Password Protection
                  </Typography>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={landingPage.isPasswordProtected}
                        onChange={(e) => setLandingPage(prev => ({ ...prev, isPasswordProtected: e.target.checked }))}
                      />
                    }
                    label={landingPage.isPasswordProtected ? "Enabled" : "Disabled"}
                  />
                  {landingPage.isPasswordProtected && (
                    <TextField
                      label="Password"
                      type={showPassword ? "text" : "password"}
                      value={landingPage.password}
                      onChange={(e) => setLandingPage(prev => ({ ...prev, password: e.target.value }))}
                      fullWidth
                      sx={{ mt: 2 }}
                      InputProps={{
                        endAdornment: (
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword(!showPassword)}
                            edge="end"
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        ),
                      }}
                    />
                  )}
                </Box>
              </Box>
            </Box>
            
            <Divider sx={{ my: 3 }} />
            
            {/* Company Info Preview */}
            <Box sx={{ mb: 4 }}>
              <Typography variant="h6" gutterBottom>
                Company Information
              </Typography>
              
              <Card sx={{ mb: 3, p: 3, textAlign: 'center' }}>
                {companyLogo && (
                  <Box sx={{ mb: 2, display: 'flex', justifyContent: 'center' }}>
                    <img 
                      src={companyLogo} 
                      alt={companyName} 
                      style={{ maxHeight: '100px', maxWidth: '100%' }} 
                    />
                  </Box>
                )}
                
                <Typography variant="h5">
                  {companyName || 'Your Company Name'}
                </Typography>
                
                <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                  This information is pulled from your user profile. To update it, go to your profile settings.
                </Typography>
              </Card>
            </Box>
            
            <Divider sx={{ my: 3 }} />
            
            {/* Form Selection */}
            <Box sx={{ mb: 4 }}>
              <Typography variant="h6" gutterBottom>
                Select Forms
              </Typography>
              <Typography variant="body2" color="text.secondary" paragraph>
                Choose which forms will appear on your landing page and add FAQs for each form.
              </Typography>
              
              {forms.length === 0 ? (
                <Paper sx={{ p: 3, textAlign: 'center', bgcolor: 'background.default' }}>
                  <Typography variant="body1" color="text.secondary">
                    You don't have any forms yet. Create a form first to add it to your landing page.
                  </Typography>
                </Paper>
              ) : (
                <List sx={{ bgcolor: 'background.paper', borderRadius: 1, border: '1px solid', borderColor: 'divider' }}>
                  {forms.map((form, index) => (
                    <React.Fragment key={form.id}>
                      {index > 0 && <Divider />}
                      <ListItem
                        alignItems="flex-start"
                        sx={{
                          flexDirection: { xs: 'column', sm: 'row' },
                          py: { xs: 2, sm: 1 }
                        }}
                      >
                        <ListItemText 
                          primary={
                            <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: 1, mb: { xs: 1, sm: 0 } }}>
                              <Typography variant="subtitle1" component="span">
                                {form.title}
                              </Typography>
                              {form.selected && form.faqs.length === 0 && (
                                <Chip 
                                  label="No FAQs added"
                                  color="warning"
                                  size="small"
                                  variant="outlined"
                                />
                              )}
                              {form.selected && form.faqs.length > 0 && (
                                <Chip 
                                  label={`${form.faqs.length} FAQ${form.faqs.length > 1 ? 's' : ''}`}
                                  color="primary"
                                  size="small"
                                />
                              )}
                            </Box>
                          }
                          secondary={form.description || 'No description'}
                          sx={{ mb: { xs: 1, sm: 0 } }}
                        />
                        
                        <Box sx={{ 
                          display: 'flex', 
                          alignItems: 'center', 
                          mt: { xs: 1, sm: 0 }, 
                          ml: { xs: 0, sm: 'auto' },
                          flexWrap: { xs: 'wrap', sm: 'nowrap' },
                          gap: 1
                        }}>
                          <FormControlLabel
                            control={
                              <Switch 
                                checked={form.selected}
                                onChange={() => toggleFormSelection(index)}
                                size="small"
                              />
                            }
                            label="Include"
                          />
                          
                          {form.selected && (
                            <Button 
                              variant="outlined" 
                              size="small"
                              onClick={() => openFAQEditor(index)}
                              startIcon={<Edit />}
                            >
                              Manage FAQs
                            </Button>
                          )}
                        </Box>
                      </ListItem>
                    </React.Fragment>
                  ))}
                </List>
              )}
            </Box>
            
            <Box sx={{ 
              display: 'flex', 
              justifyContent: 'flex-end', 
              mt: 4,
              flexDirection: { xs: 'column', sm: 'row' },
              gap: 2
            }}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={saveLandingPage}
                disabled={saving || !landingPage.companySlug || !slugAvailable}
                fullWidth={matches}
              >
                {saving ? 'Saving...' : 'Save Landing Page'}
              </Button>
              <Button
                variant="outlined"
                startIcon={<Visibility />}
                onClick={() => window.open(`/${landingPage.companySlug}`, '_blank')}
                fullWidth={matches}
              >
                Preview Landing Page
              </Button>
            </Box>
          </Paper>
          
          {/* Preview Section */}
          <Typography variant="h5" sx={{ mb: 2 }}>Preview</Typography>
          <Paper 
            sx={{ 
              p: { xs: 2, sm: 4 }, 
              borderRadius: 2, 
              mb: 4,
              borderTop: `4px solid ${resolveThemeByName(landingPage.theme).primary}` // Show the theme color
            }}
          >
            <Box sx={{ textAlign: 'center', mb: 4 }}>
              {companyLogo && (
                <Box sx={{ mb: 2, display: 'flex', justifyContent: 'center' }}>
                  <img 
                    src={companyLogo} 
                    alt={companyName} 
                    style={{ maxHeight: '100px', maxWidth: '100%' }} 
                  />
                </Box>
              )}
              
              <Typography variant="h4" gutterBottom>
                {landingPage.title || 'Form Selection'}
              </Typography>
              
              <Typography variant="body1" paragraph>
                {landingPage.description || 'Please select a form to fill out.'}
              </Typography>
            </Box>
            
            <Box sx={{ mb: 4 }}>
              <TextField
                select
                fullWidth
                label="Select a Form"
                value={previewSelectedFormId}
                onChange={(e) => handlePreviewFormChange(e as any)}
                SelectProps={{
                  native: true
                }}
              >
                <option value="" disabled={forms.filter(f => f.selected).length > 0}>
                  {forms.filter(f => f.selected).length > 0 ? 'Select a form' : 'No forms selected'}
                </option>
                {forms.filter(f => f.selected).map(form => (
                  <option key={form.id} value={form.id}>{form.title}</option>
                ))}
              </TextField>
            </Box>
            
            {previewSelectedFormId && (
              <Box sx={{ mb: 3 }}>
                <Typography variant="h6" gutterBottom>Form FAQs</Typography>
                
                {/* Show FAQs for the selected form only */}
                {(() => {
                  const selectedForm = forms.find(f => f.id === previewSelectedFormId);
                  
                  if (!selectedForm || selectedForm.faqs.length === 0) {
                    return (
                      <Typography variant="body2" color="text.secondary" align="center" sx={{ py: 2 }}>
                        No FAQs added for this form yet.
                      </Typography>
                    );
                  }
                  
                  return (
                    <Box>
                      {selectedForm.faqs.map(faq => (
                        <Accordion key={faq.id} sx={{ mb: 1 }}>
                          <AccordionSummary expandIcon={<ExpandMore />}>
                            <Typography fontWeight="medium">{faq.question}</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Typography>{faq.answer}</Typography>
                          </AccordionDetails>
                        </Accordion>
                      ))}
                    </Box>
                  );
                })()}
                
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
                  <Button 
                    variant="contained" 
                    color="primary"
                    onClick={() => previewSelectedFormId && handlePreviewFormSelection(previewSelectedFormId)}
                  >
                    Continue to Form
                  </Button>
                </Box>
              </Box>
            )}
          </Paper>
        </Container>
        
        {/* FAQ Editor Drawer */}
        {editingFormIndex !== null && (
          <Dialog
            open={true}
            onClose={closeFAQEditor}
            fullWidth
            maxWidth="md"
            scroll="paper"
          >
            <DialogTitle>
              <Typography noWrap>
                Manage FAQs for {forms[editingFormIndex!]?.title}
              </Typography>
            </DialogTitle>
            <DialogContent dividers>
              <Box sx={{ mb: 3 }}>
                <Button 
                  variant="outlined" 
                  color="primary"
                  startIcon={<Add />}
                  onClick={addNewFAQ}
                >
                  Add FAQ
                </Button>
              </Box>
              
              {forms[editingFormIndex]?.faqs.length === 0 ? (
                <Box sx={{ py: 4, textAlign: 'center' }}>
                  <Typography color="text.secondary">
                    No FAQs added yet. Click "Add FAQ" to create your first FAQ item.
                  </Typography>
                </Box>
              ) : (
                <List sx={{ width: '100%' }}>
                  {forms[editingFormIndex].faqs.map((faq) => (
                    <React.Fragment key={faq.id}>
                      <ListItem
                        alignItems="flex-start"
                        sx={{ 
                          flexDirection: { xs: 'column', sm: 'row' },
                          py: { xs: 2, sm: 1 }
                        }}
                      >
                        <ListItemText
                          primary={faq.question}
                          secondary={faq.answer.length > 100 ? `${faq.answer.substring(0, 100)}...` : faq.answer}
                          sx={{ mb: { xs: 2, sm: 0 }, width: '100%' }}
                        />
                        <Box sx={{ 
                          display: 'flex', 
                          ml: { xs: 0, sm: 2 },
                          mt: { xs: 1, sm: 0 }
                        }}>
                          <IconButton onClick={() => editFAQ(faq)}>
                            <Edit />
                          </IconButton>
                          <IconButton color="error" onClick={() => deleteFAQ(faq.id)}>
                            <Delete />
                          </IconButton>
                        </Box>
                      </ListItem>
                      <Divider component="li" />
                    </React.Fragment>
                  ))}
                </List>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={closeFAQEditor}>Close</Button>
            </DialogActions>
          </Dialog>
        )}
        
        {/* FAQ Add/Edit Dialog */}
        <Dialog 
          open={faqDialogOpen} 
          onClose={() => setFaqDialogOpen(false)} 
          maxWidth="md" 
          fullWidth
          sx={{
            '& .MuiDialog-paper': {
              width: { xs: '95%', sm: '80%' },
              maxHeight: { xs: '80vh', sm: '70vh' }
            }
          }}
        >
          <DialogTitle>
            {editingFAQ ? 'Edit FAQ' : 'Add New FAQ'}
          </DialogTitle>
          <DialogContent>
            <Box sx={{ pt: 1, display: 'flex', flexDirection: 'column', gap: 3 }}>
              <TextField
                label="Question"
                fullWidth
                value={faqQuestion}
                onChange={(e) => setFaqQuestion(e.target.value)}
              />
              
              <TextField
                label="Answer"
                fullWidth
                multiline
                rows={matches ? 3 : 4}
                value={faqAnswer}
                onChange={(e) => setFaqAnswer(e.target.value)}
              />
            </Box>
          </DialogContent>
          <DialogActions sx={{ flexDirection: { xs: 'column', sm: 'row' }, alignItems: 'stretch', p: { xs: 2, sm: 1 } }}>
            <Button 
              onClick={() => setFaqDialogOpen(false)}
              fullWidth={matches}
              sx={{ mb: matches ? 1 : 0 }}
            >
              Cancel
            </Button>
            <Button 
              onClick={saveFAQ} 
              variant="contained" 
              color="primary"
              fullWidth={matches}
            >
              {editingFAQ ? 'Update FAQ' : 'Add FAQ'}
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </ThemeProvider>
  );
};

export default LandingPageBuilder;